
// Matoma script to send the event to matoma for the tracking.
var _paq = (window._paq = window._paq || []);

function initMatomo() {
    _paq.push(['requireConsent']);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);
    (function () {
        var u = "/js/";
        var matomoTrackingApiUrl = "https://apply.payabl.com/analytics/";
        _paq.push(["setTrackerUrl", matomoTrackingApiUrl + "matomo.php"]);
        _paq.push(["setSiteId", "9"]);
        var d = document,
            g = d.createElement("script"),
            s = d.getElementsByTagName("script")[0];
        g.async = true;
        g.src = u + "matomo.js";
        s.parentNode.insertBefore(g, s);
    })();
}

function trackEvent(category, action, name, ...params) {
    if (window._paq) {
        window._paq.push(["trackEvent", category, action, name, ...params]);
    } else {
        console.error("Matomo script is not initialized.");
    }
}

function setCustomUserId(userId) {
    if (window._paq) {
        window._paq.push(['setUserId', userId]);
    } else {
        console.error("Matomo script is not initialized.");
    }
}

function setRememberConsentGiven() {
    if (window._paq) {
        window._paq.push(['rememberConsentGiven']);
    } else {
        console.error("Matomo script is not initialized.");
    }
}

export { initMatomo, trackEvent, setCustomUserId, setRememberConsentGiven };
