import React, { useState, useEffect } from "react";
import { setRememberConsentGiven } from "../../matomoScript";
import styles from "./ConsentBanner.module.css";

const CONSENT_KEY = "matomo_consent";
const CONSENT_GRANTED = "granted";
const CONSENT_DENIED = "denied";

const ConsentBanner = () => {
    const [consent, setConsent] = useState(null);

    useEffect(() => {
        const storedConsent = localStorage.getItem(CONSENT_KEY);
        if (storedConsent) {
            setConsent(storedConsent);
            if (storedConsent === CONSENT_GRANTED) {
                setRememberConsentGiven();
            }
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem(CONSENT_KEY, CONSENT_GRANTED);
        setConsent(CONSENT_GRANTED);
        setRememberConsentGiven();
    };

    const handleDecline = () => {
        localStorage.setItem(CONSENT_KEY, CONSENT_DENIED);
        setConsent(CONSENT_DENIED);
    };

    if (consent) return null;

    return (
        <div className={styles.consentBannerWrapper}>
            <div className={styles.consentBannerInner}>
                <span className={styles.consentText}>
                    We are committed to protecting your privacy while providing
                    valuable insights to enhance your experience. Our User
                    Behavioral Analytics is powered by Matomo, a privacy-focused
                    analytics platform designed to ensure your data remains
                    secure and compliant with GDPR.
                </span>
                <span className={styles.consentText}>
                    All data collected is securely stored on our servers, and we
                    analyse user interactions, such as navigation patterns and
                    engagement trends, to improve our services and optimise your
                    experience—all without collecting any personally
                    identifiable information (PII).
                </span>
                <span className={styles.consentText}>
                    We do not share, sell, or misuse your data in any way. We
                    prioritise your trust and uphold your right to privacy,
                    demonstrating our commitment to transparency and ethical
                    data practices. To find out more, please see our
                    <a href="https://payabl.com/privacy-policy" target="_blank" rel="noopener noreferrer"> privacy
                    policy</a>.
                </span>

                <div className={styles.buttonsWrapper}>
                    <button
                        className="btn btn-primary btn-lg"
                        onClick={handleDecline}
                        style={{
                            width: "10em",
                            backgroundColor: "transparent",
                            color: "white",
                        }}
                    >
                        Decline
                    </button>
                    <button
                        className="btn btn-primary btn-lg"
                        style={{
                            width: "10em",
                            marginLeft: "2em",
                        }}
                        onClick={handleAccept}
                    >
                        Accept
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConsentBanner;
