import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  downloadTransData,
  downloadTransBreakDownData,
} from "../../../actions/transLogAction";
import { downloadPOSTransactions } from "../../../actions/posTransactionsAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ScaleLoader } from "react-spinners";
import Countdown, { zeroPad } from "react-countdown";
import { trackEvent } from "../../../matomoScript";
import {EVENT_CATEGORIES} from "../../../lib/constants/events";

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitForDownload: false,
      showNoDataAlert: false,
      txDownloadNotAllowed: false,
      isPOSTransaction: !!props.isPOSTransaction ?? false,
    };
  }

  convertDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  }

  async transactionBreakDownDownload(filter) {
    this.setState({ waitForDownload: true });

    // Ensure filter.dateTo is a Date object
    const inputDate = new Date(filter.dateTo);
    const inputDateFrom = new Date(filter.dateFrom);
    // Create a new Date object for yesterday relative to today's date
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Format the dates to YYYY-MM-DD for comparison
    const formattedInputDate = inputDate.toISOString().split("T")[0];
    const formattedYesterday = yesterday.toISOString().split("T")[0];
    const formattedToday = today.toISOString().split("T")[0];

    // Matomo tracking
    const txLogParamsMid = filter?.txLogParams?.mid?.[0];
    const convertedDateFrom = this.convertDate(inputDateFrom);
    const convertedDateTo = this.convertDate(inputDate);

    if (
      txLogParamsMid != null &&
      txLogParamsMid !== "" &&
      convertedDateFrom != null &&
      convertedDateFrom !== ""
    ) {
      trackEvent(
        EVENT_CATEGORIES.DOWNLOAD,
        `${this.props?.category
          ?.replace("download_settlement_stmt", "download_statement")
          ?.replace("_stmt", "")}_tx_report`,
        `${txLogParamsMid}_${convertedDateFrom}_${convertedDateTo}`
      );
    }

    // Check if the input dates are before June 2023
    if (this.checkDatesBeforeCutoff(inputDateFrom, inputDate)) {
      this.setState({ waitForDownload: false });
      return; // Stop further execution if any date is before the cutoff
    }

    let requestBody = filter;

    const response = await this.props.downloadTransBreakDownData(requestBody);

    // --------------------------------------------------- Creating Excel File--------------------------------------------
    const responseTypeTextCsv = "text/csv";
    const responseTypeJson = "application/json";
    const fileNameDefault = this.state.isPOSTransaction
      ? "pos_transactionLog.csv"
      : "transactionLog.csv";

    // Helper function to handle alerts and set the loading state
    const handleDownloadComplete = (message, showNoDataAlert = false) => {
      alert(message);
      this.setState({ waitForDownload: false, showNoDataAlert });
    };

    // Helper function to extract file name from headers
    const extractFileName = (headers) => {
      const contentDisposition = Object.keys(headers).find(
        (key) => key.toLowerCase() === "content-disposition"
      );
      return contentDisposition
        ? headers[contentDisposition].split("=")[1]
        : fileNameDefault;
    };

    // Helper function to trigger file download
    const downloadFile = (data, fileName) => {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: responseTypeTextCsv })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    };

    // Handle the response
    if (response) {
      const { payload, headers, status } = response;

      // Handle CSV file download
      if (payload && payload.type === responseTypeTextCsv) {
        const fileName = extractFileName(headers);
        downloadFile(payload, fileName);
        this.setState({ waitForDownload: false, showNoDataAlert: true })
      }
      // Handle JSON response types
      else if (payload && payload.type === responseTypeJson) {
        switch (status) {
          case 202:
            handleDownloadComplete("Your report is being processed. You will receive an email when it is done.");
            break;
          case 416:
            handleDownloadComplete("Your download request exceeds the permitted record limit.");
            break;
          case 403:
            handleDownloadComplete("Authentication failed.");
            break;
          case 400:
            handleDownloadComplete("Downloading report for today or yesterday is not allowed.");
            break;
          default:
            this.setState({ waitForDownload: false, showNoDataAlert: true });
            break;
        }
      }
    } else {
      // Check for invalid date
      if (
        formattedInputDate === formattedToday ||
        formattedInputDate === formattedYesterday
      ) {
        handleDownloadComplete(
          "Downloading report for today or yesterday is not allowed."
        );
        return;
      }

      this.setState({ waitForDownload: false, txDownloadNotAllowed: true });
    }
  }

  checkDatesBeforeCutoff(dateFrom, dateTo) {
    const cutoffDate = new Date("2023-07-01");
    if (dateFrom < cutoffDate || dateTo < cutoffDate) {
      alert("Earliest possible download date is 2023-07-01");
      return; // Indicate that at least one date is before the cutoff
    }
  }

  async transactionLogDownload() {
    this.setState({ waitForDownload: true });

    let requestBody = this.props.filter;

    const response = this.state.isPOSTransaction
      ? await this.props.downloadPOSTransactions(requestBody)
      : await this.props.downloadTransData(requestBody);

    // --------------------------------------------------- Creating Excel File--------------------------------------------
    const responseType = "text/csv";
    let fileName = "transactionLog.csv";

    if (response) {
      if (
        response &&
        response.payload &&
        response.payload.type === responseType
      ) {
        Object.keys(response.headers).map((item) => {
          if (item === "content-disposition") {
            fileName = response.headers[item].split("=")[1];
          }
          return null;
        });

        const url = window.URL.createObjectURL(
          new Blob([response.payload], {
            type: responseType,
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        this.setState({ waitForDownload: false });
      } else {
        this.setState({ waitForDownload: false, showNoDataAlert: true });
      }
    } else {
      this.setState({
        waitForDownload: false,
        txDownloadNotAllowed: true,
      });
    }
  }

  render() {
    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <ScaleLoader color={"#03d3d8"} height={25} />;
      } else {
        // Render a countdown
        return [
          <span key="in1">
            Downloading.. <br />
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>,
          <ScaleLoader key="in2" color={"#03d3d8"} height={5} />,
        ];
      }
    };
    if (this.props.type === "log") {
      return (
        <button
          className="txLogBtn btn"
          onClick={(e) => this.transactionLogDownload()}
          disabled={this.state.waitForDownload || this.props.disabled}
        >
          {this.state.waitForDownload ? (
            <Countdown
              date={Date.now() + (this.props.records / 2000 + 20) * 1000}
              renderer={renderer}
            />
          ) : (
            <FontAwesomeIcon icon={faDownload} style={{ fontSize: "1.6rem" }} />
          )}
        </button>
      );
    }

    if (this.props.type === "breakdown") {
      if (this.props.shouldShowCompactButton) {
        return (
          <button
            className="txLogBtn btn"
            onClick={(e) => {
              this.transactionBreakDownDownload(
                this.props.transLogData.filterData
              );
            }}
            disabled={this.state.waitForDownload || this.props.disabled}
          >
            {this.state.waitForDownload ? (
              <ScaleLoader color={"#03d3d8"} height={25} />
            ) : (
              <>
                Settlement <br /> Report
              </>
            )}
          </button>
        );
      } else {
        return (
          <button
            className="fa fa-download fa-1x"
            onClick={(e) => {
              this.transactionBreakDownDownload(this.props.filter);
            }}
            disabled={this.state.waitForDownload || this.props.disabled}
            style={{ height: "25px" }}
          >
            {this.state.waitForDownload ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : null}
          </button>
        );
      }
    }

    // If type is not "breakdown", return null or some other component as per your need.
    return null;
  }
  /*this.state.waitForDownload ? (
      <WaitModal
        show={this.state.waitForDownload}
        text="Your Transaction Log is being downloaded. Please wait..."
      />
    ) : null*/
}

const mapStateToProps = (state) => ({
  transLogData: state.transLogReducer,
});

const mapDispatchToProps = {
  downloadTransData,
  downloadTransBreakDownData,
  downloadPOSTransactions,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Download)
);
export { Download };
