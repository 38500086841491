import React, { Component } from "react";
import SelectFilter from "../core/Select";
import InputFilter from "../core/Input";
import moment from "moment-timezone";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BIN_REGION_BY_ID, TX_LOG_FILTERS, FINANCE_FILTERS, DEFAULT_FILTERS, CHECKBOXFIELDS, DATE15MIN, DATE1MONTH } from "../../../lib/constants/filter";
import { TransactionLogFilter } from "./TransactionLogFilter";
import DateFilter from "./DateFilter";
import * as routes from "../../../lib/constants/routes";
import _ from "lodash";
import { SELECT_FRAUD_REPORT_CURRENCY } from "../../../lib/constants/filterData";
import Select from "react-select";
import classnames from "classnames";
import { trackEvent } from "../../../matomoScript";
import {EVENT_CATEGORIES} from "../../../lib/constants/events";

export class Filter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...DATE1MONTH,
      filterData: {},
      transLogDateSetted: false,
      isMobile: false,
      windowWidth: 0,
      fraud_report_currency: "USD",
      transactionLogFilterOpened: false,
      paddingBot: '',
      onFilterChangeValue: "",
    };

    this.mobileWidth = 600;
    this.CARD_BRAND = "card_brand";
    this.REMOVE_VALUE = "remove-value";
    this.CLEAR = "clear";
    this.BIN_REGION = "bin_region";

    this.updateFilter = this.updateFilter.bind(this);
    this.filterOutFiltersAndRefreshData = this.filterOutFiltersAndRefreshData.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.txLogFilter = this.txLogFilter.bind(this);
    this.financeFilter = this.financeFilter.bind(this);
    this.renderSingleSelect = this.renderSingleSelect.bind(this);
    this.changeFraudCurrency = this.changeFraudCurrency.bind(this);
    this.transactionLogFilterIsOpen = this.transactionLogFilterIsOpen.bind(this);

    this.inputRef = {
      order_id: React.createRef(),
      transaction_id: React.createRef(),
      email: React.createRef()
    }

    this.txFilterRef = React.createRef();
  }

  transactionLogFilterIsOpen(isOpen) {
    this.setState({
      transactionLogFilterOpened: isOpen
    })
  }

  updateFilter(dateObj, data, updateTable) {
    this.setState({
      ...dateObj,
      filterData: data
    }, () => {
      Object.entries(this.inputRef).forEach(([k, v]) => v.current.updateValue());
      if (updateTable) {
        this.props.showLoadingScreen(true);
        this.filterOutFiltersAndRefreshData();
      } else {
        this.props.showLoadingScreen(false);
      }
    });
  }

  filterOutFiltersAndRefreshData() {
    const filterData = this.state.filterData;
    const checkedValue = "1";
    let filters = {};

    Object.keys(filterData).map(key => {
      if (filterData[key] && CHECKBOXFIELDS.includes(key)) {
        filters[key] = [checkedValue];
      } else if (filterData[key] && (Array.isArray(filterData[key]) && filterData[key].length > 0)) {
        if (typeof filterData[key] == "object") {
          filters[key] = filterData[key];
        } else {
          let array = filterData[key].split(",");
          filters[key] = array;
        }
      }
      else if (filterData[key] && typeof filterData[key] == "string") {
        filters[key] = [filterData[key]];
      }
      else {
        delete filters[key];
      }
      return null;
    });

    if (filters && this.props.hasMounted) {
      this.setState({ filters: filters }, () => {
        this.props.updateBody(this.state);
        this.props.getData(this.state);
      });
    } else {
      this.setState({ filters: {} }, () => {
        this.props.updateBody(this.state);
      });
    }
  }

  updateFilterDataAndRefresh(key, value) {
    const prevState = this.state;

    const obj = key === this.CARD_BRAND ? {
      filterData: {
        ...prevState.filterData,
        paymentmethod_id: value !== "" ? ["1"] : "", // Set paymentmethod to credit card if card brand was selected.
        [key]: value
      }
    } : {
      filterData: {
        ...prevState.filterData,
        [key]: value
      }
    }

    this.setState(
      (state, props) => (obj),
      () => {
        if (this.txFilterRef.current) this.txFilterRef.current.updateFieldValues();
        this.props.showLoadingScreen(true);
        return this.filterOutFiltersAndRefreshData();
      }
    );
  }

  handleDatePicker(event, picker) {
    trackEvent(
        EVENT_CATEGORIES.FILTER,
        "change_filter_date",
        `${moment(picker.startDate).format("YYYYMMDD")}_${moment(picker.endDate).format("YYYYMMDD")}`
    );
    event.preventDefault();

    this.setState(
      (state, props) => ({
        dateFrom: moment(picker.startDate).format("YYYY-MM-DD"),
        dateTo: moment(picker.endDate).format("YYYY-MM-DD"),
        datetimeFrom: moment(picker.startDate).format("YYYY-MM-DDTHH:mm:ss"),
        datetimeTo: moment(picker.endDate).format("YYYY-MM-DDTHH:mm:ss")
      }),
      () => {
        if (this.txFilterRef.current) this.txFilterRef.current.updateFieldValues();
        this.props.showLoadingScreen(true);
        return this.filterOutFiltersAndRefreshData();
      }
    );
  }

  onChange(value, event) {
    this.onFilterChangeValue = event?.option?.value;
    if (event?.name !== undefined && event?.option?.value !== undefined) {
      trackEvent(
          EVENT_CATEGORIES.FILTER,
          `change_filter_${event.name}`,
          event.option.value
      );
  }
    if (event?.action === this.REMOVE_VALUE) {
      let array = this.state.filterData[event.name] ? [...this.state.filterData[event.name]] : []; // make a separate copy of the array
      let index = array.indexOf(event.removedValue.value);

      if (index !== -1) {
        array.splice(index, 1);
        this.updateFilterDataAndRefresh(event.name, array.length === 0 ? "" : array);
      }
      // Reset to initial input event
      Object.entries(this.inputRef).forEach(([k, v]) =>{
        v.current.resetEvent()
      })

    } else if (event?.action === this.CLEAR) {
      this.updateFilterDataAndRefresh(event.name, "");
      Object.entries(this.inputRef).forEach(([k, v]) =>{
        v.current.resetEvent()
      })
    }

    if (event?.option && value) {
      const values = [];

      Object.keys(value).map(function (key) {
        values.push(value[key].value);
        return null;
      });

      this.updateFilterDataAndRefresh(event.name, values);
    }
  }

  handleWindowResize() {
    if (window.innerWidth < this.mobileWidth && !this.state.isMobile) {
      this.setState({ windowWidth: window.innerWidth, isMobile: true });
    }

    if (window.innerWidth > this.mobileWidth && this.state.isMobile) {
      this.setState({ windowWidth: window.innerWidth, isMobile: false });
    }
  }

  componentDidMount() {
    this.filterOutFiltersAndRefreshData();
    window.addEventListener("resize", this.handleWindowResize);

    this.setState({
      windowWidth: window.innerWidth,
      isMobile: window.innerWidth < this.mobileWidth
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  getSnapshotBeforeUpdate(nextProps, nextState) {
    if (!_.isEqual(nextState, this.state) && nextState.isMobile === this.state.isMobile && nextState.windowWidth === this.state.windowWidth) {
      this.props.changeFilter(true);
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Changes the displayed date to 15 minutes for the transaction log page.
    if (this.props.onTransLogPage && !this.state.transLogDateSetted) {
      this.setState({
        ...DATE15MIN,
        transLogDateSetted: true
      });
    }

    // Changes the displayed date to this month for all pages except transaction log.
    if (!this.props.onTransLogPage && this.state.transLogDateSetted) {
      this.setState({
        ...DATE1MONTH,
        transLogDateSetted: false
      });
    }

    return null;
  }

  inputField(name, label) {
    let values = [];
    if (this.state.filterData[name] && this.state.filterData[name] !== "") {
      this.state.filterData[name].map(v => values.push({ label: v, value: v }));
    }

    return (
      <div key={name} className={`filter-field col-lg-2 col-md-3 col-sm-4 col-xs-6 ${this.state.paddingBot}`}>
        <InputFilter
          key={name}
          label={label}
          valueArr={values}
          onValueChange={this.onChange}
          name={name}
          disabled={this.state.transactionLogFilterOpened}
          expanderEnabled={true}
          ref={this.inputRef[name]}
        />
      </div>
    );
  }

  selectField(name, label) {
    if (label === "Acquirer") {
      if (!this.props.serverFilterData[name]) {
        // we prevent the selectfilter from creation if we dont have any options
        return null;
      }
    }

    let values = [];
    if (this.state.filterData[name] && this.state.filterData[name] !== "") {
      this.state.filterData[name].map(v => {
        values.push({
          label: name === this.BIN_REGION ? BIN_REGION_BY_ID[v] : v,
          value: v
        });
        return null;
      });
    }

    return (
      <div key={name} className="filter-field col-lg-2 col-md-3 col-sm-4 col-xs-6">
        <SelectFilter
          key={name}
          label={label}
          value={values}
          onChange={this.onChange}
          options={this.props.serverFilterData}
          name={name}
          disabled={this.state.transactionLogFilterOpened}
        />
      </div>
    );
  }

  txLogFilter() {
    const res = Object.keys(TX_LOG_FILTERS).map(key => {
        if (TX_LOG_FILTERS[key].type === 'input') {
          return this.inputField(TX_LOG_FILTERS[key].id, TX_LOG_FILTERS[key].title)
        } else {
          return this.selectField(TX_LOG_FILTERS[key].id, TX_LOG_FILTERS[key].title)
        }
      }
    )

    return (
      <div className="row">
        <div className="col-md-12 col-xs-12">
          { res }
        </div>
      </div>
    );
  }

  financeFilter() {
    const res = Object.keys(FINANCE_FILTERS).map(key => {
        if (FINANCE_FILTERS[key].type === 'input') {
          return this.inputField(FINANCE_FILTERS[key].id, FINANCE_FILTERS[key].title)
        } else {
          return this.selectField(FINANCE_FILTERS[key].id, FINANCE_FILTERS[key].title)
        }
      }
    )

    return (
      <div className="col-md-12 col-xs-12" style={{padding:"0px"}}>
        { res }
      </div>
    );
  }

  defaultFilter() {
    const res = Object.keys(DEFAULT_FILTERS).map(key => {
        if (DEFAULT_FILTERS[key].type === 'input') {
          return this.inputField(DEFAULT_FILTERS[key].id, DEFAULT_FILTERS[key].title)
        } else {
          return this.selectField(DEFAULT_FILTERS[key].id, DEFAULT_FILTERS[key].title)
        }
      }
    )

    return (
      <div className="col-md-12 col-xs-12" style={{padding:"0px"}}>
        { res }
        {
          this.props.activeTab === routes.FRAUD_TAB ? (
            this.renderSingleSelect("fraud_report_currency",SELECT_FRAUD_REPORT_CURRENCY,"Select Fraud Report Currency",
                this.state.fraud_report_currency, this.changeFraudCurrency )
          ) : null
        }
      </div>
    );
  }

  changeFraudCurrency( value ) {

    this.setState({
      fraud_report_currency : value
    });

    this.props.updateFraudReport( value.value );
  }

  renderSingleSelect(name, options, placeholder, value, method ) {
    return (
      <div className="form-group filter-field">
        <div style={{ width: 250 }}>
          <Select
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={method}
            options={options}
            styles={{control: (base, state) => ({
              ...base,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              border: "none",
              boxShadow: "none",
              cursor: "pointer",
              borderRadius: "6px",
              "&:hover": {
                border: "1px solid #35A7E3"
                }
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                color: "#1E6AF6",
              }),}
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div id="filterCollapse" className="filter-box box collapse in frosted-glass row" style={{backgroundColor:"none", boxShadow:"none"}}>
          <div className="tab-content" id="myTabContent1">
            <div className=" tab-pane active box-body form-inline filter-search-tab"
              id="search"
              role="tabpanel"
              aria-labelledby="search-tab">

              {/* !this.state.isMobile ? (TODO*/}
              <div className="row">

	              <div className="col-xs-6">
                  <DateFilter datetimeFrom={this.state.datetimeFrom} datetimeTo={this.state.datetimeTo} onApply={this.handleDatePicker} disabled={this.state.transactionLogFilterOpened} />
	              </div>

	              { this.props.currentPath === routes.TRANS_LOG ? (
		              <div className="col-xs-6 text-right nav-item more-search-options-btn">
		                <button
		                  className="primaryBtn2"
		                  type="button"
                      style={{height:"40px", width:"150px", float:"right", fontWeight:"normal", borderWidth:"1px"}}
                      onClick={() => this.transactionLogFilterIsOpen(true)}
                      disabled={this.state.transactionLogFilterOpened}
		                >
                      {this.state.isMobile ? (
                        <><span className="glyphicon glyphicon-search"></span><span className="glyphicon glyphicon-plus"></span></>
                      ) : "Advanced Search"}
		                </button>
	              	</div>
	            	) : null}
            	</div>

              { this.props.currentPath === routes.TRANS_LOG ? (
                this.txLogFilter()
              ) : this.props.currentPath === routes.FINANCE_DATA ? (
                this.financeFilter()
              ) : (
                this.defaultFilter()
              )}
            </div>

          </div>
        </div>

        { this.props.currentPath === routes.TRANS_LOG ? (
          <div className={classnames({
            "advanced-filter-close":!this.state.transactionLogFilterOpened,
            "advanced-filter-open":this.state.transactionLogFilterOpened,
          })}>
            <TransactionLogFilter
              serverFilterData={this.props.serverFilterData}

              filterData={this.state.filterData}
              dateFrom={this.state.dateFrom}
              dateTo={this.state.dateTo}
              datetimeFrom={this.state.datetimeFrom}
              datetimeTo={this.state.datetimeTo}
              updateFilter={this.updateFilter}

              transactionLogFilterOpened={this.state.transactionLogFilterOpened}
              transactionLogFilterIsOpen={this.transactionLogFilterIsOpen}

              ref={this.txFilterRef}
            />
          </div>
        ) : null}
      </>
    );
  }
}