import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { sideBarMenuToggle } from "../../../actions/dashboardAction";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { logout } from "../../../actions/userAction";
import {
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
} from "../../../lib/constants/utils/token";
import cookieUtils from "../../../lib/constants/utils/cookies";
import { initMatomo, trackEvent } from "../../../matomoScript";
import { EVENT_CATEGORIES } from "../../../lib/constants/events";
import ConsentBanner from "../../general/ConsentBanner";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            isSignedOut: false,
        };
        this.username = localStorage.getItem("username");
        this.isSideBarToggleClicked = false;

        this.toggle = this.toggle.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    componentDidMount() {
        initMatomo();
    }

    sideBarToggleClicked = (e) => {
        e.preventDefault();
        this.isSideBarToggleClicked = !this.isSideBarToggleClicked;
        this.props.sideBarMenuToggle(this.isSideBarToggleClicked);
        trackEvent(EVENT_CATEGORIES.CLICK, "click_sidebar_toggle");
    };

    toggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    onMouseEnter() {
        this.setState({ dropdownOpen: true });
    }

    onMouseLeave() {
        this.setState({ dropdownOpen: false });
    }

    async signOut() {
        window.Intercom("shutdown");

        let accessToken = {
            Authorization: `Backoffice ${cookieUtils.get(ACCESS_TOKEN_KEY)}`,
        };
        let refreshToken = cookieUtils.get(REFRESH_TOKEN_KEY);
        await this.props.logout(accessToken, { refresh_token: refreshToken });
        localStorage.clear();
        cookieUtils.removeAllCookies();
        this.setState({ isSignedOut: true });
    }

    clickOnLogo() {
        trackEvent(EVENT_CATEGORIES.CLICK, "click_payabl_logo");
    }

    clickOnSearch() {
        trackEvent(EVENT_CATEGORIES.CLICK, "click_search_toggle");
    }

    render() {
        if (this.state.isSignedOut === true) {
            return <Redirect to="/" />;
        }

        return (
            <header className="main-header">
                {/*<LoadingScreen />*/}
                <Link
                    to="#"
                    className="logo"
                    style={{ backgroundColor: "#000" }}
                >
                    <span className="logo-mini">
                        <img
                            src="/img/payabl_small.ico"
                            style={{
                                height: "23px",
                                marginRight: "1%",
                                color: "white",
                                fontWeight: "bold",
                            }}
                            alt="p."
                        />
                    </span>
                    <span className="logo-lg">
                        <img
                            src="/img/payabl_white_main.svg"
                            style={{
                                height: "40px",
                                width: 100,
                                marginRight: "1%",
                                color: "white",
                                fontWeight: "bold",
                            }}
                            alt="payabl."
                        />
                    </span>
                </Link>

                <nav
                    className="navbar navbar-fixed-top"
                    style={{ backgroundColor: "#000" }}
                >
                    {/* Sidebar toggle */}
                    <Link
                        to="#"
                        className="sidebar-toggle"
                        data-toggle="push-menu"
                        role="button"
                        onClick={this.sideBarToggleClicked}
                    >
                        <span className="sr-only">Toggle navigation</span>
                    </Link>

                    {/* Search filter toggle */}
                    {this.props.pathname !== "/generic/virtualTerminalTab" &&
                    this.props.pathname !== "/generic/adminTab" &&
                    this.props.pathname !== "/generic/paymentLinkTab" &&
                    this.props.pathname !== "/generic/trustly_balancesTab" &&
                    this.props.pathname !== "/generic/payment-link-details" ? (
                        <h4 className="panel-title">
                            <Link
                                to="#"
                                className="search-toggle"
                                type="button"
                                data-toggle="collapse"
                                data-target="#filterCollapse"
                                aria-expanded="false"
                                aria-controls="filterCollapse"
                                onClick={this.clickOnSearch}
                            >
                                <span className="glyphicon glyphicon-search"></span>
                            </Link>
                        </h4>
                    ) : null}

                    <div className="navbar-custom-menu header-username">
                        <Dropdown
                            className="d-inline-block"
                            /*onMouseOver={this.onMouseEnter}
						onMouseLeave={this.onMouseLeave}*/
                            isOpen={this.state.dropdownOpen}
                            toggle={this.toggle}
                        >
                            <DropdownToggle
                                caret
                                style={{
                                    height: "44px",
                                    backgroundColor: "transparent",
                                }}
                            >
                                <Link to="#" style={{ color: "white" }}>
                                    {this.username + " "}
                                    <i className="fa fa-user fa-lg circle-icon" />
                                </Link>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <img
                                        src="/img/icons/Logout.svg"
                                        alt="."
                                        width="17"
                                        height="17"
                                    />
                                    <span
                                        onClick={this.signOut}
                                        style={{ color: "white" }}
                                    >
                                        Logout
                                    </span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <ConsentBanner />
                    </div>
                </nav>
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    loginData: state.loginData,
});

const mapDispatchToProps = {
    logout,
    sideBarMenuToggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
